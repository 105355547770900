import "./App.css";
import { Route, Routes } from "react-router-dom";

import Home from "./pages/Home";
import Wheel from "./components/Wheel";


function App() {


  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/wheel" element={<Wheel />} />

      </Routes>
    
    </div>
  );
}

export default App;
